export const adminEmailPortalUserSearchPath = (): string => '/api/v1/admin/email_portal/user_search'

export type Arg = string | number // Do not use this type anymore. Arguments should be string, not number, number is kept here only for legacy purposes.
export const addSupplierToListPath = (listId: Arg): string => `/api/v1/lists/${listId}/add_supplier`
export const agencySearchPath = (): string => '/api/v1/agencies'
export const canaryContractSpendByOrganizationPath = (contractId: string): string => `/api/v1/canary/contracts/${contractId}/spend_by_organization`
export const canaryContractSuppliersWithSpendPath = (contractId: string): string => `/api/v1/canary/contracts/${contractId}/suppliers_with_spend`
export const canaryPurchasingContacts = (contractId: string): string => `/api/v1/canary/contracts/${contractId}/purchasing_contacts`
export const commodityCodePath = (segmentId: Arg): string => `/api/v1/commodity_codes/${segmentId}`
export const commodityCodeSearchPath = (): string => '/api/v1/commodity_codes/search'
export const commodityCodeSegmentPath = (): string => '/api/v1/commodity_codes/segments'
export const commodityCodesPath = (): string => '/api/v1/commodity_codes'
export const contractVehicleSupplierReviewsForSupplierPath = (contractVehicleId: string, supplierId: string): string => `/api/v1/contract_vehicles/${contractVehicleId}/suppliers/${supplierId}/reviews_for_supplier`
export const contractVehicleSupplierReviewsPath = (contractVehicleId: Arg, supplierId: Arg): string => `/api/v1/contract_vehicles/${contractVehicleId}/suppliers/${supplierId}/reviews`
export const contractVehiclesContractsPath = (contractVehicleId: string): string => `/api/v1/contract_vehicles/${contractVehicleId}/contracts`
export const contractVehiclesSuppliersPath = (contractVehicleId: string): string => `/api/v1/contract_vehicles/${contractVehicleId}/suppliers`
export const contractVehiclesSuppliersSearchPath = (contractVehicleId: string): string => `/api/v1/contract_vehicles/${contractVehicleId}/suppliers/search`
export const createImagePath = (): string => '/api/v1/images'
export const createSessionPath = (): string => '/api/v1/sessions'
export const createUserPath = (): string => '/api/v1/users'
export const deleteAccountNotePath = (noteId: Arg): string => `/api/v1/admin/account_notes/${noteId}`
export const fetchAccountNotesPath = (): string => '/api/v1/admin/account_notes'
export const listsPath = (): string => '/api/v1/lists'
export const processAgencyRegisteredSupplierPath = (supplierId: Arg): string => `/api/v1/admin/suppliers/${supplierId}`
export const proxyReviewCampaignPath = (campaignId: Arg): string => `/api/v1/proxy_review_campaigns/${campaignId}`
export const proxyReviewCampaignsPath = (): string => '/api/v1/proxy_review_campaigns'
export const removeSupplierFromListPath = (listId: Arg): string => `/api/v1/lists/${listId}/remove_supplier`
export const reviewAssociateUserPath = (reviewId: Arg): string => `/api/v1/reviews/${reviewId}/associate_user`
export const reviewRecordEmailPath = (reviewId: Arg): string => `/api/v1/reviews/${reviewId}/record_email`
export const reviewRepliesPath = (reviewId: Arg): string => `/api/v1/reviews/${reviewId}/review_replies`
export const reviewReplyPath = (replyId: Arg): string => `/api/v1/review_replies/${replyId}`
export const reviewsAggregateRatingsPath = (): string => '/api/v1/reviews/aggregate_ratings'
export const reviewsCompetitorRatingsPath = (): string => '/api/v1/reviews/competitor_ratings'
export const reviewsCurrentRatingsPath = (): string => '/api/v1/reviews/current_ratings'
export const reviewsPaginatedReviewsPath = (): string => '/api/v1/reviews/paginated_reviews'
export const reviewsSummaryPath = (): string => '/api/v1/reviews/summary'
export const sendUserAuthenticationPin = (): string => '/api/v1/users/authentication_pin'
export const setUserPasswordPath = (): string => '/api/v1/users/password'
export const statesPath = (): string => '/api/v1/states'
export const supplierPostsPath = (supplierId: Arg): string => `/api/v1/suppliers/${supplierId}/user_posts`
export const supplierReviewsPath = (supplierId: Arg): string => `/api/v1/suppliers/${supplierId}/reviews`
export const supplierSearchPath = (): string => '/api/v1/suppliers'
export const updateImagePath = (imageId: Arg): string => `/api/v1/images/${imageId}` // Also used for delete
export const userConfirmWithAssistancePath = (): string => '/api/v1/users/confirm_with_assistance'
export const userConfirmationPath = (): string => '/api/v1/users/confirm'
export const userStatusPath = (): string => '/api/v1/users/status'
